:root {
  /* Main Settings  */
  /* --main-background-color: linear-gradient(
    140deg,
    rgba(255, 99, 132, 0.2) 0%,
    rgba(54, 162, 235, 0.2) 50%,
    rgba(255, 206, 86, 0.2) 75%
  ); */

  --main-background-color: linear-gradient(
    108.6deg,
    rgb(66, 38, 137) 19%,
    rgb(16, 112, 90) 91.7%
  );

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(0, 0, 0);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: #000;
  --nav-height: 100px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(155, 155, 155);
  --mobile-nav-logo-height: 100px;
  --mobile-nav-link-color: rgb(65, 65, 65);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 0px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #151a1f;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;

  /* Minting Button */
  --minting-button-color: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  --minting-button-text-color: white;
}
