@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap");

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--main-text-color);
  position: relative;
  /* background: var(--main-background-color); */
  margin: 0;
  font-family: "Patrick Hand SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  width: 100%;
  height: 80px;
  transition: height 0.2s ease-in-out;
  max-width: 1200px;
  margin: 0px auto 60px auto;

  /* border-bottom: 1px solid #fff; */
}

nav .links {
  flex: 1;
  display: flex;
  padding: 0px 20px;
}

nav .links .link {
  margin: 0px 10px;
  text-decoration: none;
  padding: 5px;
  transition: 0.5s;
  border-radius: 5px;
}

nav .links .link:hover {
  background: #fff;
  color: #000;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 100%;
  padding: 10px 20px;
  position: relative;
}

.nav-logo {
  width: 200px;
  object-fit: scale-down;
  border-radius: 5px;
}

nav a:not(.link) {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a.twitter {
  margin-right: 10px;
}

nav a.discord svg {
  width: 27px;
}

.nav-social {
  width: 40px;
  height: 40px;
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.nav-social svg {
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.nav-social:hover svg {
  transform: scale(1.1);
}

nav .hamburger-wrapper {
  position: fixed;
  right: 15px;
  top: 25px;
  display: none;
  z-index: 99999;
}

nav .mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 250px;
  height: 100%;
  background: #fff;
  transition: transform 0.5s;
  z-index: 9999;
  border-left: 2px solid #e27483;
}

nav .mobile-menu.hide {
  transform: translateX(100%);
}

.mobile-menu .links {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex: 0;
}

.mobile-menu .link {
  color: #000 !important;
  margin: 0px 10px;
}

.mobile-menu .social-icons {
  justify-content: center;
  width: 100%;
}

.text-muted {
  color: darkgrey;
}

.nav-social:hover svg {
  transform: scale(1.1);
}

.social-icons {
  display: flex;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  gap: 20px;
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.top-right {
  display: flex;
  justify-content: flex-end;
}

.top-right img {
  max-width: 300px;
  border-radius: 10px;
  border: 1px solid #fff;
  object-fit: scale-down;
  background-color: #fff;
}

.top-card {
  background: #000;
  color: #fff;
  overflow: hidden;
  min-height: 350px;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
}

.top-card > div {
  flex: 0 0 calc(50% - 20px);
}

.top-card .top-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.what-is {
  margin: 50px auto 30px auto;
}

.teaser {
  position: relative;
  margin-top: 50px;
}

.teaser h2 {
  text-align: center;
}

.teaser .embed {
  display: flex;
  justify-content: center;
}

.teaser .badge {
  position: absolute;
  max-width: 150px;
}

.teaser .badge-1 {
  top: 30%;
  left: 0px;
  transform: translate(-50%, -50%) rotate(340deg);
  opacity: 0.65;
}

.teaser .badge-2 {
  top: 60%;
  right: 0px;
  transform: translate(50%, -50%) rotate(13deg);
  opacity: 0.6;
}

.team-w {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.team-w h2 {
  text-align: center;
  margin-bottom: 30px;
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-card {
  display: flex;
  flex: 0 0 calc(50% - 20px);
  box-sizing: border-box;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  min-height: 170px;
  background: #fff;
}

.team-card a {
  text-decoration: none;
}

.team-card .card-img {
  max-width: 120px;
  object-fit: contain;
  margin-left: 1px;
  border-radius: 5px;
}

.team-card .info {
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-card .info * {
  color: #000 !important;
}

.partners-w {
  display: flex;
  flex-direction: column;
}

.partners-w h2 {
  text-align: center;
  margin: 30px 0px;
}

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners .partner {
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.5s;
}

.partners .partner:hover {
  transform: scale(1.05);
}

.partners .partner img {
  max-width: 150px;
}

.partners .partner h4 {
  background: #fff;
  width: 100%;
  color: #000 !important;
  text-align: center;
}

.faq-container {
  margin-top: 30px;
}

.faq-row-wrapper {
  border-radius: 10px;
}

.faq-row-wrapper * {
  color: #000 !important;
}

.faq-row-wrapper svg {
  fill: #000 !important;
}

.faq-row-wrapper .faq-row {
  margin: 20px 0px;
  background: #000;
  border-radius: 5px;
  background: #fff;
}

.faq-row-wrapper .row-title {
  font-weight: 600;
  color: #fff !important;
}

.faq-row-wrapper .faq-title {
  border: none !important;
  padding-bottom: 0px !important;
}

.faq-row-wrapper .faq-title h2 {
  padding-left: 0px !important;
}

.faq-row-wrapper .row-title,
.faq-row-wrapper h2 {
  padding-left: 10px !important;
  color: #fff !important;
}

footer {
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding: 25px 0px;
}

footer .stay-w {
  padding-top: 5px;
}

footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .divider {
  margin: 0px 10px;
}

footer .logo {
  max-width: 120px;
  border-radius: 5px;
  /* height: 100px; */
}

footer .nav-social {
  transform: scale(0.8);
}

@media only screen and (max-width: 1050px) {
  .team {
    flex-direction: column;
    align-items: center;
  }
  .team-card {
    max-width: 500px;
  }
}

@media only screen and (max-width: 950px) {
  .nav-logo {
    width: 150px;
  }
  nav .links.desktop {
    display: none;
  }
  nav .social-icons.desktop {
    display: none;
  }
  nav .hamburger-wrapper {
    display: block;
  }
  .top-right img {
    max-width: 250px;

    border: none;
  }
}

@media only screen and (max-width: 800px) {
  .top-right img {
    max-width: 250px;
    border: none;
  }
  .top-card {
    flex-direction: column;
  }
  .top-left {
    width: 100%;
    align-items: center;
  }
  .badge {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .team-card {
    max-width: none;
    flex-direction: column;
    align-items: center;
  }
  .team-card .card-img {
    margin-top: 1px;
  }
  .top-right {
    height: 250px;
  }
  .top-card {
    border-radius: 0px;
  }
  body {
    overflow-x: hidden;
  }
  .nav-social {
    width: 35px;
    height: 35px;
  }
  .faq-row-wrapper h2 {
    font-size: 20px !important;
  }
}
